<template>
  <div class="auditInfoPage">
    <div class="facilityBox">
      <el-tabs
        v-model="flag.activeCmpt"
        @tab-click="cmptChange"
      >
        <el-tab-pane
          label="待审核"
          name="1"
        ></el-tab-pane>
        <el-tab-pane
          label="已审核"
          name="2"
        ></el-tab-pane>
        <el-tab-pane
          label="批量审核"
          name="3"
        ></el-tab-pane>
      </el-tabs>
      <el-form
        class="manageForm"
        :model="manageForm"
        :inline="true"
      >
        <el-form-item
          label="服务商名称："
          prop="SupplierName"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.SupplierName"
            placeholder="输入服务商名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="企业名称："
          prop="EnterpriseFullName"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.EnterpriseFullName"
            placeholder="输入企业名称"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            class="fromInp"
            v-model="manageForm.remark"
            placeholder="输入备注"
          ></el-input>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button
            type="primary"
            size="medium"
            @click="search()"
            icon="el-icon-search"
          >搜索</el-button>
          <el-button
            type="primary"
            size="medium"
            @click="resetForm()"
            icon="el-icon-delete"
          >清空</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="tableData"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="loading"
      >
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          fixed
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="SupplierName"
          label="服务商名称"
          fixed
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseFullName"
          label="企业名称"
          fixed
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="备注"
          fixed
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseCreditCode"
          label="企业统一社会信用代码"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="企业地址"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          v-if="flag.activeCmpt != 2"
          prop="CheckApplyTime"
          label="申请时间"
          show-overflow-tooltip
        ></el-table-column>
        <!-- <el-table-column v-if="flag.activeCmpt == 2" align="center" prop="DealTimeInfo" label="人工审核时间"
          show-overflow-tooltip></el-table-column> -->
        <el-table-column
          align="center"
          v-if="flag.activeCmpt != 2"
          prop="WayReviewCount"
          label="总计运单数量"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="EnterpriseAddress"
          label="操作"
          width="130"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="
              manualClick(
                scope.row,
                pagination.page,
                pagination.pagesize,
                'waitAudit'
              )
              "
              icon="el-icon-user-solid"
              v-if="flag.activeCmpt == 1"
            >人工审核</el-button>
            <el-button
              type="primary"
              size="small"
              @click="
              manualClick(
                scope.row,
                pagination.page,
                pagination.pagesize,
                flag.activeCmpt == 2 ? 'alreadyAudit' : 'autoAudit'
              )
              "
              v-else
            >详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="pagination.page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          :page-size="pagination.pagesize"
          @size-change="paginationSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { GetReviewWayList } from "@/api/manualAudit/index";
import { compareTime } from "@/utils/utils";
export default {
  data() {
    return {
      flag: {
        activeCmpt: "1", //当前激活的tab页
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        remark: '',
        SupplierName: ''
      },
      // 表格loading
      loading: false,
      // 表格数据
      tableData: [],
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
    };
  },
  created() {
    this.flag.activeCmpt = this.$route.meta.pagetype == 'alreadyAudit' ? "2" : (this.$route.meta.pagetype == "autoAudit" ? "3" : "1")
    this.$route.meta.pagetype = "";
    if (this.$route.query.pageIndex) {
      this.pagination.page = Number(this.$route.query.pageIndex);
      this.pagination.pagesize = Number(this.$route.query.pageSize);
    }
    // 获取人工审核列表
    this.GetReviewWayList();
  },
  mounted() {
    // 监听浏览器刷新
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
  },
  destroyed() {
    // 移除浏览器刷新
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
  },
  methods: {
    cmptChange(tab) {
      this.flag.activeCmpt = tab.name;
      this.pagination.page = 1;
      this.tableData = [];
      if (this.flag.activeCmpt != 2) {
        this.GetReviewWayList();
      }
    },
    // 浏览器刷新重置参数
    beforeunloadHandler(e) {
      if (this.$route.path == "/auditInfo/manualAudit/index") {
        // 重置路由
        this.$router.push({
          path: "/auditInfo/manualAudit/index",
        });
      }
    },
    // 人工审核
    manualClick(item, pageIndex, pageSize, type) {
      this.$router.push({
        path: "/auditInfo/manualAudit/auditInfo",
        query: {
          UserID: item.UserID,
          EnterpriseFullName: item.EnterpriseFullName,
          remark: item.Remark,
          pageIndex,
          pageSize,
          type,
        },
      });
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      // 获取人工审核列表
      this.GetReviewWayList();
    },
    // 清空搜索
    resetForm() {
      this.tableData = [];
      this.pagination.page = 1;
      this.manageForm = {
        EnterpriseFullName: "",
        remark: '',
        SupplierName: ''
      };
      // 获取人工审核列表
      this.GetReviewWayList();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      // 获取人工审核列表
      this.GetReviewWayList()
    },
    paginationSizeChange(e) {
      this.pagination.pagesize = e;
      // 获取人工审核列表
      this.GetReviewWayList()
    },
    // 获取人工审核列表
    GetReviewWayList() {
      this.loading = true;
      let manualStatusList = this.flag.activeCmpt == 1 ? [0] : this.flag.activeCmpt == 2 ? [1, 2] : [10];
      let data = {
        enterpriseFullName: this.manageForm.EnterpriseFullName,
        remark: this.manageForm.remark,
        manualStatusList, //0未审核 1,2已审核
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize,
        SupplierName: this.manageForm.SupplierName
      };
      GetReviewWayList(data)
        .then((res) => {
          if (res.data.DataList && res.data.DataList.length) {
            // this.tableData = res.data.DataList.sort(
            //   compareTime("CheckApplyTime", "inverted")
            // );
            this.tableData = res.data.DataList
          } else {
            this.tableData = [];
          }
          this.pagination.total = Number(res.data.TotalCount);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  // computed: {
  //   currentList() {
  //     return this.tableData.slice(
  //       this.pagination.pagesize * (this.pagination.page - 1),
  //       this.pagination.pagesize * this.pagination.page
  //     );
  //   },
  // },
};
</script>

<style lang="scss" scoped></style>
